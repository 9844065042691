import ColoredBox from 'components/ColoredBox'
import { useAppDispatch, useAppSelector } from 'commons/store/hooks'
import React, {useEffect, useState} from 'react'
import {
    fetchAdverseEvents,
    fetchAdverseEventsFields,
    selectAdverseEvents,
    selectEcrf
} from 'features/ecrf/redux/ecrfSlice'
import TableForm from 'components/Table/TableForm'
import ModalForm from "components/Form/Modal/ModalForm";
import ElementTable from "core/helpers/ElementTable";
import { enqueueSnackbar } from 'notistack'
import { useSearchParams } from "react-router-dom";
import { FilterPanel } from "./FilterPanel";
import {useTranslation} from "react-i18next";

const DashboardAdverseEvents = () => {
    const dispatch = useAppDispatch();
    const adverseEvents = useAppSelector(selectAdverseEvents);
    const ecrf = useAppSelector(selectEcrf);
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [page, setPage] = useState(1)
    const [columns, setColumns] = useState<any[]>([]);
    const [isLoading, setLoading] = useState(true);

    const [data, setData] = useState<any>({
        itemsPerPage: 0,
        totalItems: 0,
        _embedded: {item: []}
    });

    /**
     * This function is used to fetch ecrves list
     */
    const fetchData = async (initial?: boolean) => {
        setLoading(true);

        try {
            let fields: any = [];
            if (initial) {
                fields = await dispatch(fetchAdverseEventsFields()).unwrap();
                fields && setColumns(ElementTable.createColumns(fields.slice(0, 4)) || []);
            }

            const config = {
                headers: {
                    'Content-Type': 'application/hal+json',
                    Accept: 'application/hal+json',
                },
                params: {
                    pagination: true,
                    page,
                    itemsPerPage: 30,
                    ...Object.fromEntries(searchParams)
                },
            };

            // const response = await dispatch(fetchAdverseEvents({page, randomId})).unwrap();
            const response = await dispatch(fetchAdverseEvents(config)).unwrap();
            response && setData({ rows: response._embedded?.item });
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.response?.data, { variant: 'warning' });
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchData(true).then()
    }, [dispatch, page, ecrf]);

    useEffect(() => {
        // setPage(1)
        fetchData(true).then()
    }, [searchParams]);

    return (
        <ColoredBox pt={0} px={5} maxWidth="100%" ml="auto" mr="auto">
            <FilterPanel/>
            <TableForm
                collections={!isLoading && {rows: adverseEvents._embedded?.item || []}}
                pagination={{
                    current: page,
                    pageSize: adverseEvents.itemsPerPage,
                    total: adverseEvents.totalItems
                }}
                page={page}
                setPage={setPage}
                columns={columns}
                tableName={t('adverse-events')}
                elementName={adverseEvents && adverseEvents.length ? adverseEvents[0].elementName : ''}
                isLoading={isLoading}
                ecrfOption={true}
            />
            <ModalForm/>
        </ColoredBox>
    )
}

export default DashboardAdverseEvents
