import { flatten, forEach } from "lodash";

export async function bindInitData(data: any, ecrfId: string): Promise<any> {
  return data.map((element: any) => {
    return {
      ecrf: `/api/ecrves/${ecrfId}`,
      element: `/api/elements/${element?.id}`,
      data: [],
    }
  });
}

export async function bindData(values: any, ecrfId: string, data: any = []): Promise<any> {
  let bindData: any = [];

  if (data) {
    // map previous elements data
    bindData = Object.values(data || []).map(
      (el: any) => {
        if (typeof values[el?.element?.id] !== 'undefined') {
          return {
            id: `/api/element_datas/${el.id}`,
            data: flatten([values[el?.element?.id]]),
          }
        }

        return { id: `/api/element_datas/${el.id}` }
      }
    )
  }

  forEach(values, (value, eId) => {
    if (data && Object.values(data).findLast((el: any) => el?.element?.id === eId)) {
      return;
    }

    bindData.push({
      ecrf: `/api/ecrves/${ecrfId}`,
      element: `/api/elements/${eId}`,
      data: flatten([value]),
    });
  });

  return bindData;
}
