import React from 'react';
import { Box, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DATE_FORMAT } from "commons/constants/env";
import { isValid, parse } from "date-fns";
import { Filter } from "../Filter";
import { useTranslation } from "react-i18next";

export interface FilterPanelProps {}

export const FilterPanel = (props: FilterPanelProps) => {
    const { t } = useTranslation();

    const filters: any[] = [
        {
            render: (filter: any, fn: any) => (
                <Box display={'flex'} gap={'20px'} alignItems="center">
                    <Tooltip title={t('start-randomization-range')} arrow>
                        <>
                            <DatePicker
                                label={t('start')}
                                value={
                                    isValid(parse(filter?.['createdAt[after]'], DATE_FORMAT, new Date()))
                                        ? parse(filter?.['createdAt[after]'], DATE_FORMAT, new Date())
                                        : null
                                }
                                format={DATE_FORMAT}
                                onChange={(newValue) => fn(newValue, 'createdAt[after]')}
                                slotProps={{
                                    actionBar: {
                                        actions: ['today', 'clear']
                                    },
                                    textField: {
                                        size: 'small',
                                    }
                                }}
                            />
                        </>
                    </Tooltip>
                    -
                    <Tooltip title={t('end-randomization-range')} arrow>
                        <>
                            <DatePicker
                                label={t('end')}
                                value={
                                    isValid(parse(filter?.['createdAt[before]'], DATE_FORMAT, new Date()))
                                        ? parse(filter?.['createdAt[before]'], DATE_FORMAT, new Date())
                                        : null
                                }
                                format={DATE_FORMAT}
                                onChange={(newValue) => fn(newValue, 'createdAt[before]')}
                                slotProps={{
                                    actionBar: {
                                        actions: ['today', 'clear']
                                    },
                                    textField: {
                                        size: 'small',
                                    }
                                }}
                            />
                        </>
                    </Tooltip>
                </Box>
            ),
            key: 'createdAt',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('screening-number')} arrow>
                    <TextField
                        label={t('screening-number')}
                        value={filter?.['screeningNumber'] || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'screeningNumber')
                        }
                        fullWidth
                        variant={'outlined'}
                        size={'small'}
                    />
                </Tooltip>
            ),
            key: 'screeningNumber',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('query-author')} arrow>
                    <TextField
                        label={t('added')}
                        value={filter?.['ownerName'] || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'ownerName')
                        }
                        size={'small'}
                        fullWidth
                        variant={'outlined'}
                    />
                </Tooltip>
            ),
            key: 'ownerName',
        },
        {
            render: (filter: any, fn: any) => (
                <Tooltip title={t('recipient-query')} arrow>
                    <TextField
                        label={t('recipient')}
                        value={filter?.['recipientName'] || ''}
                        onChange={(event) =>
                            fn(event.target.value, 'recipientName')
                        }
                        size={'small'}
                        fullWidth
                        variant={'outlined'}
                    />
                </Tooltip>
            ),
            key: 'recipientName',
        },
    ];

    return <Filter elements={filters} />;
};
