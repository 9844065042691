import { Modal, Box, Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "commons/store/hooks";
import CloseIcon from "@mui/icons-material/Close";
import {
    fetchElementDataCollectionHistory,
    resetElementDataCollectionHistory,
} from "../../features/ecrf/redux/elementDataCollectionSlice";
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import DateTime from "../../core/helpers/date/DateTime";
import { DATETIME_FORMAT } from "../../commons/constants/env";
import { useTranslation } from "react-i18next";

interface ModalProps {
    children?: any[]
    open: boolean | string;
    onClose: () => void;
}

interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

export const RowHistoryModal: React.FC<ModalProps> = (props) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<Column[]>([])
    const [rows, setRows] = useState<any[]>([]);
    const { t } = useTranslation();

    const createColumns = () => {
        const initColumns: Column[]= [
            {
                id: '_element',
                label: t('element'),
                minWidth: 170,
                format: (value: any) => value,
            },
            {
                id: 'date',
                label: t('date'),
                minWidth: 170,
                format: (value: any) => DateTime.toIso(value, DATETIME_FORMAT),
            },
            // {
            //     id: 'field',
            //     label: 'Field',
            //     minWidth: 170,
            //     format: (value: any) => value,
            // },
            {
                id: 'old_value',
                label: t('old-value'),
                minWidth: 170,
                format: (value: any) => Array.isArray(value)
                  ? value?.map((value: any) => (value?.value) ? value?.value : value)
                  : JSON.stringify(value)
            },
            {
                id: 'new_value',
                label: t('new-value'),
                minWidth: 170,
                format: (value: any) => Array.isArray(value)
                  ? value?.map((value: any) => (value?.value) ? value?.value : value)
                  : JSON.stringify(value)
            },
            {
                id: 'user',
                label: t('user'),
                minWidth: 170,
                format: (value: any) => value,
            },
        ]

        // props?.children?.forEach((el: any, index: any) => {
        //     let type = el.type;
        //
        //     switch (type) {
        //         default: {
        //             initColumns.push({
        //                 id: el.label,
        //                 label: el.label,
        //                 minWidth: 170,
        //                 // align: 'right',
        //                 format: (value: any) => value,
        //             })
        //             break;
        //         }
        //     }
        // })

        setColumns(initColumns || [])
    }

    const fetch = useCallback(async () => {
        setLoading(true);
        try {
            if (typeof props.open === 'string') {
                const data = props.open && await dispatch(fetchElementDataCollectionHistory(props.open)).unwrap();
                setRows(data)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    }, [dispatch, props.open]);

    useEffect(() => {
        createColumns()

        if (props.open && typeof props.open === 'string') {
            fetch();
        }

        /** clear state after unmount */
        return () => {
            dispatch(resetElementDataCollectionHistory());
        };
    }, [dispatch, props.open, fetch]);

    return (
        <>
            <Modal
                open={!!props.open}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{overflow: "scroll"}}
            >
                <Box m={3} ml="20vw" mr="20vw" p={3} bgcolor="white" borderRadius="10px">
                    <Box textAlign="end">
                        <Button
                            startIcon={<CloseIcon/>}
                            color="primary"
                            onClick={props.onClose}
                            variant="outlined"
                        >
                            {t('close')}
                        </Button>
                    </Box>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rows.length === 0 || loading) && (
                                    <TableRow tabIndex={-1}>
                                        <TableCell
                                            colSpan={columns.length}
                                            align="center"
                                        >
                                            {loading ? (
                                                <>
                                                    {new Array(3).fill(null).map((_, index) => {
                                                        return <Skeleton key={index} style={{padding: "10px", margin: "5px"}} animation="wave"/>
                                                    })}
                                                </>
                                            ) : (
                                                <Box>{t('no-data')}</Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(!loading && rows.length > 0) && rows
                                    .slice()
                                    .map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                {columns.map((column: any, columnIndex: number) => {
                                                    const value = row[columnIndex];
                                                    const formatValue = column.format ? column.format(value) : value;
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {columnIndex === 0 ? <b>{formatValue}</b> : formatValue}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </>
    );
};
