import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import {
  fetchEcrf,
  fetchEcrfElements,
  fetchElement,
  fetchElementDataCollection,
  fetchElementDatas,
  postElementDataCollection,
  putCollectElementData,
  putElementData as putElementDataDeprecated,
  putElementDataCollection,
  selectElementDataCollection,
  selectElementDatas
} from "features/ecrf/redux/ecrfSlice";
import {
  postElementData,
  putElementData,
} from "features/ecrf/redux/elementDataSlice";
import { useEffect, useState } from "react";
import ModalFormContent from "./ModalFormContent";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import getInitialValues from "../../../features/ecrf/utils/getInitialValues";
import { useTranslation } from "react-i18next";
import { addElementDataCollection } from "../../../features/ecrf/redux/elementDataCollectionSlice";
import { bindInitData } from "../../../features/ecrf/utils/bindDataElementDataCollection";

type ModalFormFieldProps = {
  handleClose: () => void;
  collection: any;
  title: string
}

const ModalFormField = ({
  handleClose,
  collection,
  title
}: ModalFormFieldProps) =>{
  const dispatch = useAppDispatch();
  const elementData = useAppSelector(selectElementDatas)
  const elementDataCollection = useAppSelector(selectElementDataCollection)
  const { enqueueSnackbar} = useSnackbar();
  const { t } = useTranslation();

  const { id, section } = useParams<{ id: string, section: string }>();
  const [isLoading, setLoading] = useState(true)
  const [isSaving, setSaving] = useState(false)
  const [elements, setElements] = useState<any>([]);
  const [ed, setEd] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState(t('loading-data') + '...')
  const [collectionId, setCollectionId] = useState(null)

  const fetch = async () => {
    setLoading(true);

    const ecrfId = collection?.ecrfId || id;

    // check children elements and add if exists
    if (collection?.children && collection?.children?.length > 0) {
      setElements(collection.children);
    } else if (collection?.elementId) {
      const parentElement = await dispatch(fetchElement(collection?.elementId)).unwrap();
	    parentElement && setElements(parentElement.children);
    }

    switch (collection?.type) {
      case 'add':
      case 'created':
        if (!collection.options?.autosave) {
          break;
        }

        let dataId = ed?.id || collection.dataId;

        if (!collection.id && (ecrfId && collection?.elementId)) {
          const bindData: any = await bindInitData(collection.children, ecrfId);
          const col = await dispatch(addElementDataCollection({
            ecrf: collection?.ecrfId,
            element: collection?.elementId,
            elements: bindData
          })).unwrap();

          if (col) {
            setCollectionId(col?.id);
            const edc = await dispatch(fetchElementDataCollection(col.id)).unwrap();
            setData(edc?.elements);
            // dataId = col?.elementData?.id;
          }
        }

        dataId
          ? await dispatch(fetchElementDatas(dataId))
          : await dispatch(fetchEcrfElements({ id, sectionId: section }));

        break;
      case 'edit':
      case 'duplicate':
      case 'save':
        if (['button'].includes(collection?.elementType)) {
          const ed = await dispatch(fetchElementDatas(collection.dataId)).unwrap();
          setData(ed?.children);
        } else if (collection.id) {
          setData([]);
          const edc = await dispatch(fetchElementDataCollection(collection.id)).unwrap();
          setData(edc?.elements);
        }
        break;
    }

    setLoading(false);
  }

  const reloadData = async () => {
    setData([]);
    setData(elementDataCollection?.elements);
  }

  useEffect(() => {
    fetch().then();
  }, [collection])

  useEffect(() => {
    if (!isLoading) reloadData().then();
  }, [elementDataCollection])

  const initialValues = getInitialValues(elements, data || []);

  const handleSubmit = async (values: any) => {
    try {
      const dataId = collection?.dataId;
      const collectionId = collection?.id;
      const elementId = collection?.elementId;
      const ecrfId = collection?.ecrfId;
      const collectionElements: any[] = [];

      const excludeElements = elements?.filter(
        (element: any) => ['table'].includes(element.type)
      );

      // filter element values
      let valueElements = Object.entries(values).filter(
        (element: any) => !excludeElements?.find((exclude :any) => element[0] === exclude?.id)
      );

      let bindData: any = valueElements.map(
        (element: any) => {
          return {
            ecrf: `/api/ecrves/${ecrfId}`,
            element: `/api/elements/${element[0]}`,
            data: Array.isArray(element[1]) ? element[1] : [element[1]],
          }
        }
      );

      switch (collection?.type) {
        case 'add':
        case 'created':
          if (dataId) {
            collectionElements.push({ elements: bindData });
            await dispatch(putElementDataCollection({dataId, collections: collectionElements}));
          } else {
            await dispatch(postElementDataCollection({elementId, ecrfId, elements: bindData}));
          }
          break;
        case 'clone':
        case 'duplicate':
          collectionElements.push({ elements: bindData });
          await dispatch(putElementDataCollection({dataId, collections: collectionElements}));
          break;
        case 'edit':
          if (collectionId) {
            if (data?.length > 0) {
              bindData = valueElements.map((element: any) => {
                if (data?.length > 0) {
                  const elementData = data?.find(
                    (ed: any) => ed?.ecrf?.id === ecrfId && ed?.element?.id === element[0]
                  );

                  if (elementData) {
                    return {
                      id: `/api/element_datas/${elementData.id}`,
                      data: Array.isArray(element[1]) ? element[1] : [element[1]],
                    }
                  }
                }

                return {
                  ecrf: `/api/ecrves/${ecrfId}`,
                  element: `/api/elements/${element[0]}`,
                  data: Array.isArray(element[1]) ? element[1] : [element[1]],
                }
              })
            }

            await dispatch(putCollectElementData({id: collectionId, elements: bindData})).unwrap()
          }
          break;
        case 'save':
          // collect data and save element data collection
          if (['button'].includes(collection?.elementType || '')) {
            let collections: any[] = []
            if (dataId) {
              if (data?.length > 0) {
                collections = data?.map((el: any) => {
                  if (values[el?.element?.id]) {
                    return {
                      id: `/api/element_datas/${el.id}`,
                      data: [values[el?.element?.id]],
                    }
                  }

                  return {
                    id: `/api/element_datas/${el.id}`,
                    data: el?.data,
                  }
                })
              } else {
                collections = bindData;
              }

              await dispatch(putElementData({ id: dataId, params: { children: collections } }));
            } else {
              await dispatch(postElementData({
                ecrf: `/api/ecrves/${elementData?.ecrf?.id || ecrfId}`,
                element: `/api/elements/${elementData?.element?.id || elementId}`,
                children: bindData
              }));
            }
          } else {
            await dispatch(putElementDataDeprecated({ elementId, ecrfId, data: [values[elementId]] }));
          }
          break;
      }

      // refresh data
      if ((elementData?.ecrf?.id || ecrfId) && section) {
        await dispatch(fetchEcrfElements({ id: elementData?.ecrf?.id || ecrfId, sectionId: section }));
      } else if (elementData?.ecrf?.id || ecrfId) {
        await dispatch(fetchEcrf(elementData?.ecrf?.id || ecrfId));
      }

      // close modal
      handleClose();

      enqueueSnackbar(`${t('saved')} ${new Date().toLocaleTimeString()}`, { variant: 'info' });
    } catch (error: any) {
      let message = error?.message;

      if (error.response.data && error.response.data?.detail) {
        message = error.response.data;
      } else if (error?.detail && error?.violations) {
        message = error;
      }

      enqueueSnackbar(message?.detail || error?.message, { variant: message?.violations ? 'warning' : 'error' });
    }

    setSaving(false);
  }

  return (
    <ModalFormContent
      id={collectionId}
      elements={elements}
      elementsData={ed}
      elementsDatas={data}
      isLoading={isLoading}
      dataLoading={dataLoading}
      isSaving={isSaving}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      ecrfId={collection?.ecrfId}
      initialValues={initialValues}
      title={title}
      submitText={(collection?.status > 0
        ? collection?.options?.buttonDisabledText
        : collection?.options?.buttonText
      ) ?? `${t(collection.type)} ${t('record')}`}
    />
  )
}

export default ModalFormField