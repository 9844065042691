import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch } from "commons/store/hooks";
import TextFieldFormik from "components/Form/TextFieldFormik";
import { Form, Formik, FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import ColoredBox from "components/ColoredBox";
import { useTheme } from "@mui/material/styles";
import yup from "commons/yup";
import { Link } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import { forgotPassword } from "core/redux/userSlice";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const validationSchema = yup.object({
    email: yup.string().email().required(),
});

const ForgotPassword = () => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const { t } = useTranslation();
    const [isProcessing, setProcessing] = useState(false)

    const initialValues = {
        email: "",
    };

    const handleSubmit = async (
        values: typeof initialValues,
        formikHelpers: FormikHelpers<typeof initialValues>
    ) => {
        setProcessing(true);

        try {
            await dispatch(forgotPassword(values));
            enqueueSnackbar(t('fp-message-link-change-password'), {
                variant: 'success'
            });
            formikHelpers.resetForm();
        } catch (error: any) {
            enqueueSnackbar(error?.detail, {variant: 'error'});
        }

        setProcessing(false);
    };

    return (
        <Box
            minHeight="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            pl={2}
            pr={2}
        >
            <ColoredBox p={1} width={352} maxWidth="100%">
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <Typography variant="h5">{t('password-change')}</Typography>
                        <Box mt={3.5}>
                            <TextFieldFormik
                                margin="dense"
                                name="email"
                                label="Email"
                                type="email"
                                variant="filled"
                                fullWidth
                                autoComplete={'on'}
                                helperText={t('fp-email-help-text')}
                            />
                        </Box>
                        <Box mt={2} display="flex" justifyContent="flex-end">
                            <Box boxShadow={theme.shadows[4]}>
                                <Button type="submit" color="primary" variant="contained" disabled={isProcessing}>
                                    {t('send')}
                                </Button>
                            </Box>
                        </Box>
                        <Box mt={1.5} display="flex" justifyContent="flex-end">
                            <Button
                                color="primary"
                                component={Link}
                                to={ROUTES_CORE.LOGIN}
                                sx={{ textTransform: 'unset' }}
                            >
                                {t('login')}
                            </Button>
                        </Box>
                    </Form>
                </Formik>
            </ColoredBox>
        </Box>
    );
};

export default ForgotPassword;
