import { CircularProgress, Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'commons/store/hooks';
import Nav from 'commons/wrappers/DashboardLayoutWrapper/components/Nav';
import {
    fetchDependencies,
    fetchEcrf,
    fetchEcrfElements,
    fetchSectionByEcrf,
    fetchVisitsWithCounters,
    getSectionId,
    resetCollection,
    resetCurrentSection,
    resetDependencies,
    resetEcrf,
    resetElementDatas,
    selectCurrentSection,
    selectSectionId,
    selectVisits,
} from 'features/ecrf/redux/ecrfSlice';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledEcrfLayout, StyledEcrfTitle } from './Ecrf.styled';
import { FormContent } from './FormContent/FormContent';
import { LeftPanel } from './Menu/LeftPanel';
import { VisitsPanel } from './VisitsList/VisitsPanel';
import { useSnackbar } from 'notistack';
import { ROUTES_ECRF } from 'commons/constants/paths';
import { useTranslation } from "react-i18next";

export const EcrfForm: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id, section } = useParams<{ id: string, section: string }>();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    /**
     * State is holding props from routing redirect
     * @example: from dashboard, ae or query
     */
    const redirectSection = useAppSelector(selectSectionId);

    /** Current selected section data */
    const currentSection = useAppSelector(selectCurrentSection);

    /** Visits */
    const visits = useAppSelector(selectVisits);

    /** State is holding actual selected visit */
    const [selectedVisit, setSelectedVisit] = useState<any>({});

    /** State is holding actual selected section */
    const [selectedSection, setSelectedSection] = useState<any>({});

    /** Initial loading state status */
    const [initialLoading, setInitialLoading] = useState(true);

    /** Response pending status - if is true you can't change section */
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(t('loading-data') + '...')

    /**
     * Initial config - load first section of first visit
     */
    const initialConfig = async (visits: any) => {
      // console.log(' initialConfig')
        setIsDataLoading(true);

        if (!visits) return;
        setSelectedVisit(visits[0]);

        if (visits.length === 0 || !visits[0]?.sections[0]) return;
        setSelectedSection(visits[0].sections[0]?.id);

        if (visits[0].sections[0]?.id) {
            (id && !section) && navigate(ROUTES_ECRF.DETAILS_EXTENDED(id, visits[0].sections[0]?.id));

            await fetchSectionData(visits[0].sections[0].id, visits);
            await fetchElements(visits[0].sections[0].id);
        }

        setIsDataLoading(false);
    };

    /**
     * fetchElements()
     */
    const fetchElements = async (sectionId: any) => {
      // console.log(' fetchElements')
        setDataLoading(t('loading-field-dependencies') + '...');
        await dispatch(fetchDependencies(sectionId));
        setDataLoading(t('loading-field-data') + '...');
        await dispatch(fetchEcrfElements({id, sectionId}));
    }

    /**
     * fetchSectionData()
     * @todo one fn reload current section (elements, data, etc)
     */
    const fetchSectionData = async (sectionId: any, visits: any = []) => {
      // console.log(' fetchSectionData')

        setSelectedSection(sectionId);

        setDataLoading(t('loading-section-data') + '...');

        // const section: any = await dispatch(fetchSection(sectionId)).unwrap();
        const _section: any = await dispatch(fetchSectionByEcrf({ id: sectionId, ecrfId: id })).unwrap();
        if (id && _section?.id) {
            !section && navigate(ROUTES_ECRF.DETAILS_EXTENDED(id, _section?.id || sectionId), { replace: true })
            setSelectedSection(_section?.id);
        }

        if ((visits || visits.length > 0) && _section?.visit?.id) {
            const visit = visits.find((visit: any) => visit?.id === _section?.visit?.id);
            if (visit) setSelectedVisit(visit);
        }
    };

    /**
     * loadSectionData()
     */
    const loadSectionData = async (sectionId: any, visits: any = []) => {
      // console.log(' loadSectionData')

      if (isDataLoading) return;

        setIsDataLoading(true);

        try {
            await fetchSectionData(sectionId, visits);
            await fetchElements(sectionId);
        } catch (error: any) {
            enqueueSnackbar(error?.detail || error?.message, {variant: 'error'});
        }

        /** clear redirect-data after redirect */
        dispatch(getSectionId({}));

        setIsDataLoading(false);
    };

    /** Config for redirect option */
    const redirectConfig = async (visits?: any) => {
      // console.log(' redirectConfig')
        setIsDataLoading(true);

        if (redirectSection?.section) {
            await fetchSectionData(redirectSection.section, visits);
            await fetchElements(redirectSection.section);
        }

        if (redirectSection?.element) {
            setIsOpenQueryPanel(redirectSection.element);
        }

        /** clear redirect-data after redirect */
        dispatch(getSectionId({}));

        setIsDataLoading(false);
    };

    /** Config for section from route params */
    const routeSectionConfig = async (sectionId: string, visits?: any) => {
      // console.log(' routeSectionConfig')
        setIsDataLoading(true);

        try {
            await fetchSectionData(sectionId, visits);
            await fetchElements(sectionId);
        } catch (e: any) {
            await initialConfig(visits);
        }

        setIsDataLoading(false);
    };

    /** Status of Queries Panel */
    const [isOpenQueryPanel, setIsOpenQueryPanel] = useState<null | string>(null);

    /**
     * Used to fetch patient data including ECRF data and visits
     */
    const fetchEcrfData = async () => {
        // init data ecrf
        setInitialLoading(true);

        setDataLoading(t('loading-visits') + '...')
        const visits = await dispatch(fetchVisitsWithCounters(id)).unwrap();
        setDataLoading(t('loading-ecrf-data') + '...');
        const ecrf = id && await dispatch(fetchEcrf(id))?.unwrap();

        if (!ecrf) {
            return;
        }

        // init data section
        // incoming redirect data (AE, Query)
        if (redirectSection?.section !== undefined) {
          // console.log('fetchEcrfData redirectConfig')
            await redirectConfig(visits);
        } else if (section) {
          // console.log('fetchEcrfData routeSectionConfig')
            await routeSectionConfig(section, visits);
        // if exists current `section` in ecrf data
        } else if (ecrf?.currentSection?.id) {
          // console.log('fetchEcrfData loadSectionData')
            // await fetchSectionData(section || ecrf?.currentSection?.id, visits);
            await loadSectionData(ecrf?.currentSection?.id, visits);
        } else {
          // console.log('fetchEcrfData initialConfig')
            await initialConfig(visits);
        }

        setInitialLoading(false);
    }

    useEffect(() => {
      // console.log('useEffect 1')
        fetchEcrfData().then();

        /** clear state after unmount */
        return () => {
            dispatch(resetEcrf());
            dispatch(resetCurrentSection());
            dispatch(resetElementDatas());
            dispatch(resetCollection());
            dispatch(resetDependencies());
        };
    }, [id]);

    useEffect(() => {
      // console.log('useEffect 2', section, currentSection?.id)

        if ((section && currentSection?.id) && section !== currentSection?.id) {
          // console.log('useEffect 2 loadSectionData')
            loadSectionData(section, visits);
        }
    }, [section])

    return (
        <>
            <Nav />
            {!initialLoading && (
                <StyledEcrfLayout>
                    <LeftPanel
                        selectedVisit={selectedVisit}
                        selectedSection={selectedSection}
                        setSelectedSection={setSelectedSection}
                        isDataLoading={isDataLoading}
                        setIsDataLoading={setIsDataLoading}
                    />
                    <VisitsPanel
                        selectedVisit={selectedVisit}
                        setSelectedVisit={setSelectedVisit}
                        isDataLoading={isDataLoading}
                    />
                    <FormContent
                        isDataLoading={isDataLoading}
                        setSelectedVisit={setSelectedVisit}
                        setIsDataLoading={setIsDataLoading}
                        isOpenQueryPanel={isOpenQueryPanel}
                        setIsOpenQueryPanel={setIsOpenQueryPanel}
                        updateSection={loadSectionData.bind(this)}
                    />
                </StyledEcrfLayout>
            )}

            {/** Initial view with loading animation */}
            {initialLoading && (
                <Box display="grid" style={{placeItems: 'center', height: '100vh'}}>
                    <Box display="grid" style={{placeItems: 'center', gap: '15px'}}>
                        <CircularProgress />
                        <StyledEcrfTitle>{dataLoading}</StyledEcrfTitle>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default EcrfForm;
