import { isDate } from "date-fns";
import pl from "date-fns/locale/pl";
import { format } from "date-fns-tz";
import { DATETIME_FORMAT, TZ } from "../../../commons/constants/env";

export default class DateTime {
    /**
     * Format value
     * @param type
     * @param value
     * @returns any
     */
    public static formatValue(type: string = 'string', value: any): any
    {
        const date = new Date(value);
        switch (type) {
            case 'birth_date':
            case 'date': {
                return format(date, 'yyyy-MM-dd', {
                    timeZone: TZ,
                    locale: pl,
                })
            }
            case 'datetime':
            case 'datetime-local': {
                return format(date, 'yyyy-MM-dd HH:mm', {
                    timeZone: TZ,
                    locale: pl,
                })
            }
            case 'time': {
                return format(date, 'yyyy-MM-dd HH:mm', {
                    timeZone: TZ,
                    locale: pl,
                })
            }
            default: {
                return value ?? ''
            }
        }
    }

    /**
     * This method is used to validate, whether input stream has a valid datetime value or not.
     * @returns boolean
     * @param value
     */
    public static isDateTime(value: string): boolean
    {
        return !!(value &&
            value.length === 16 &&
            value[4] === '-' &&
            value[7] === '-' &&
            value[10] === 'T' &&
            value[13] === ':'
            ||
            value &&
            value.length === 24 &&
            value[4] === '-' &&
            value[7] === '-' &&
            value[10] === 'T' &&
            value[13] === ':' &&
            value[19] === '.' &&
            value[23] === 'Z');
    }

    /**
     * This method is used to validate, whether input stream has a valid timestamp format or not.
     * @param timestamp
     * @returns boolean
     */
    public static isTimeStamp(timestamp: string): boolean
    {
        return (new Date(timestamp)).getTime() > 0;
    }

    /**
     * Validate value
     * @returns boolean
     * @param value
     */
    public static isValid(value: string): boolean
    {
        return isDate(value) || this.isTimeStamp(value) || this.isDateTime(value);
    }

    /**
     * Convert value the date/time string to date object.
     * @param value
     * @param strFormat
     * @return string
     */
    public static toObject(value: string, strFormat: string | null = null): Date|string
    {
        if (!this.isValid(value)) {
            return new Date();
        }

        if (strFormat) {
            return new Date(value);
        }

        return new Date(value);
    }

    /**
     * Current method is used to convert value into the ISO based date/time string.
     * @param value
     * @param strFormat
     * @return string
     */
    public static toIso(value: string, strFormat: string | null = null): string
    {
        if (!this.isTimeStamp(value)) {
            return '';
        }

        if (strFormat) {
            return format(new Date(value), strFormat, {
                timeZone: TZ,
                locale: pl,
            });
        }

        return new Date(value).toISOString();
    }

    /**
     * Convert value into the date/time string.
     * @param value
     * @param strFormat
     * @return string
     */
    public static toString(value: string, strFormat: string | null = null): string
    {
        if (!this.isValid(value)) {
            return value;
        }

        if (strFormat) {
            return format(new Date(value), strFormat);
        }

        return new Date(value).toString();
    }
}