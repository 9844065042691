import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, { useState } from "react";
import { TableWrapper } from "./Table.styled";
import xor from "lodash/xor";
import reject from "lodash/reject";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";

export type TableProps<T> = {
    data: T[];
    tableName?: string;
    pagination?: {
        total: number;
        current: number;
        pageSize: number;
    };
    onChangePage?: (page: number) => void;
    onChangeRowsPerPage?: (page: number) => void;
    columns: Array<{
        title: string | React.ReactNode;
        render: (row: T, index: number) => React.ReactNode;
        thRender?: () => React.ReactNode;
        key: string;
        noWrap?: boolean;
        hide?: boolean;
    }>;
    rowKey?: keyof T;
    className?: string;
    isSelectable?: boolean;
    isLoading?: boolean;
    groupActions?: React.ReactNode;
    filters?: React.ReactNode;
    noDataText?: string;
};

const CustomTable = <T extends object>({
   data,
   tableName,
   columns,
   rowKey,
   pagination,
   onChangePage = () => {},
   onChangeRowsPerPage = () => {},
   isSelectable,
   isLoading = false,
   filters,
   noDataText,
}: TableProps<T>) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState<Array<string | number>>([]);
    const [isFiltersShowing, setIsFiltersShowing] = useState(false);

    const getRowKey = (index: number) => {
        return rowKey ? String(data[index][rowKey]) : index;
    };

    const handleSelectRow = (id: string | number) => {
        setSelected((prevSelected) => xor(prevSelected, [id]));
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const selected = data.map((_, index) => getRowKey(index));
            setSelected(selected);
            return;
        }
        setSelected([]);
    };

    const filteredColumns = reject(columns, (column) => !!column.hide);

    return (
        <TableWrapper>
            <Box
                pt={1}
                pb={1}
                pl={0}
                pr={0}
                display="flex"
                justifyContent="space-between"
                // alignItems="center"
                bgcolor={selected.length > 0 ? "primary.light" : undefined}
                color={selected.length > 0 ? "primary.main" : undefined}
                // minHeight={filters || (tableName && 48)}
                minHeight={(tableName && 48)}
            >
                {selected.length > 0 && (
                    <Typography variant="subtitle1">{selected.length} {t('selected-rows')}</Typography>
                )}
                {selected.length === 0 && (
                    <>
                        <Typography variant="subtitle1">{tableName}</Typography>
                        {filters && (
                            <IconButton onClick={() => setIsFiltersShowing((prevState) => !prevState)}>
                                <FilterListIcon/>
                            </IconButton>
                        )}
                    </>
                )}
            </Box>
            {filters && isFiltersShowing && (
                <Box pl={2} pr={2} mb={4} mt={1}>
                    {filters}
                </Box>
            )}
            <TableContainer sx={{ maxHeight: 460 }}>
                <Table stickyHeader size="medium" padding="none">
                    <TableHead>
                        <TableRow>
                            {isSelectable && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < data.length}
                                        checked={data.length > 0 && selected.length === data.length}
                                        onChange={handleSelectAllClick}
                                    />
                                </TableCell>
                            )}
                            {filteredColumns.map((column, columnIndex) => (
                                <TableCell
                                    key={column.key || columnIndex}
                                    style={{ paddingBottom: 10 }}
                                >
                                    {column.thRender ? column.thRender() : column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(data?.length === 0 || isLoading) && (
                            <TableRow tabIndex={-1}>
                                <TableCell
                                    colSpan={isSelectable ? filteredColumns.length + 1 : filteredColumns.length}
                                    align="center"
                                >
                                    {isLoading ? (
                                        <>
                                            {new Array(
                                                pagination?.pageSize || 3
                                            ).fill(null).map((_, index) => {
                                                return <Skeleton key={index} style={{padding: "10px", margin: "5px"}} animation="wave"/>
                                            })}
                                        </>
                                    ) : (
                                        <Box>{ noDataText ?? t('no-data') }</Box>
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                        {(!isLoading && data?.length > 0) && data?.map((row, dataIndex) => {
                            const rowKey = getRowKey(dataIndex);
                            const isItemSelected = selected.includes(rowKey);

                            return (
                                <TableRow hover tabIndex={-1} key={rowKey} selected={isItemSelected}>
                                    {isSelectable && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                onChange={() => handleSelectRow(rowKey)}
                                            />
                                        </TableCell>
                                    )}
                                    {filteredColumns.map((column, columnIndex) => (
                                        <TableCell
                                            key={column.key || columnIndex}
                                            width={column.noWrap ? "1px" : undefined}
                                            style={column.noWrap ? {whiteSpace: "nowrap"} : {}}
                                        >
                                            {column.render(row, dataIndex)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {pagination && (
                <TablePagination
                    // rowsPerPageOptions={[10, 30, 50, 100]}
                    rowsPerPageOptions={[]}
                    rowsPerPage={pagination.pageSize ?? 0}
                    component='div'
                    count={pagination.total ?? 0}
                    page={pagination.current - 1}
                    onPageChange={(_, page) => onChangePage(page + 1)}
                    // onRowsPerPageChange={(_, page) => onChangeRowsPerPage()}
                />
            )}
        </TableWrapper>
    );
};

export default CustomTable;
