import { useField } from "formik";
import { Box, Button, Typography } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField";
import ConfirmationAction from "../ConfirmationAction";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { fetchEcrf, selectEcrf } from "features/ecrf/redux/ecrfSlice";
import { randomizePatient } from "features/ecrf/redux/ecrfSlice";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { COLOR_BG_DARK } from "commons/constants/env";
import { useTranslation } from "react-i18next";

export interface TextFieldFormikProps
    extends Omit<TextFieldProps,
        "name" | "value" | "onChange" | "onBlur" | "error"> {
    name: string;
    status?: boolean;
    data?: any;
}

const ChangeRandNumberFormik = ({name, helperText, status, data, ...props}: TextFieldFormikProps) => {
    const [field, meta] = useField(name);
    const ecrf = useAppSelector(selectEcrf);
    const {id} = useParams<{ id: string }>();
    const dispatch = useAppDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();

    const nextNumber = async () => {
        try {
            await dispatch(randomizePatient(id || '')).unwrap();
            await dispatch(fetchEcrf(id || ''));
        } catch (error: any) {
            enqueueSnackbar(error.detail, {variant: 'error'});
        }
    };

    return (
        <>
            <Box bgcolor={COLOR_BG_DARK} p={3} borderRadius={'5px'}>
                <Typography variant={'body2'}>
                    {props.label}
                </Typography>
                <Typography variant={'h5'}>
                    {ecrf?.randomId || '-'}
                </Typography>

                <ConfirmationAction
                    confirmationTitle={t('are-you-sure-you-want-to-change-the-patient-randomization-number')}
                    onClick={() => {
                        nextNumber()
                    }}
                >
                    {({handleClick}) =>
                        <Box display={'grid'} justifyContent={'end'}>
                            <Button disabled={!ecrf?.randomAt} onClick={handleClick} color={'primary'}>
                                {t('next-number')}
                            </Button>
                        </Box>
                    }
                </ConfirmationAction>
            </Box>
        </>
    );
};

export default ChangeRandNumberFormik;