import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import * as util from "util";

interface ElementData {
  data: any;
  autocomplete: any;
}

const initialState: ElementData = {
  data: [],
  autocomplete: [],
};

type FetchAutocompleteParams = {
    link: string;
    args?: any;
    params?: any;
};

export const fetchAutocomplete = createAsyncThunk(
    'elementData/autocomplete',
    async ({link, args = [], params = []}: FetchAutocompleteParams, thunkAPI) => {
      try {
        // const url = util.format(link, ...args);
        const url = util.format(link);
        const response = await axiosSecureInstance.get(url, {
            params: params,
        });
        return response.data
      } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
)

type PostElementDataParams = {
  element: string;
  ecrf: string;
  data?: Array<any>;
  children?: Array<any>;
  comment?: any;
  collection?: Array<any>;
  entries?: Array<any>;
  status?: number;
};

export const postElementData = createAsyncThunk(
  'elementData/post',
  async (params: PostElementDataParams, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.post(`/api/element_datas`, params);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

type PutElementDataParams = {
  data?: Array<any>;
  children?: Array<any>;
  comment?: any;
  collection?: Array<any>;
  entries?: Array<any>;
  status?: number;
};

export const putElementData = createAsyncThunk(
  'elementData/put',
  async ({ id, params }: { id: string, params: PutElementDataParams}, thunkAPI) => {
    try {
      const response = await axiosSecureInstance.put(`/api/element_datas/${id}`, params);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const elementDataSlice = createSlice({
  name: 'elementData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAutocomplete.fulfilled, (state, action) => {
      state.autocomplete = action.payload;
    });
  },
});

export const selectAutocomplete = (state: RootState) => state.ecrf.elementData.autocomplete;

export default elementDataSlice.reducer;
