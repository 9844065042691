import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'commons/store/hooks'
import ConfirmationAction from 'components/ConfirmationAction'
import Table from 'components/Table'
import { deleteElementDataCollection, fetchCollection, getSectionId } from 'features/ecrf/redux/ecrfSlice'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ROUTES_ECRF } from "commons/constants/paths";
import { Link, useNavigate, useParams } from "react-router-dom";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { useSnackbar } from "notistack";
import { roles } from "../../commons/auth/roles";
import { selectUserProfile } from "../../core/redux/userSlice";
import { RowHistoryModal } from "../Form/RowHistoryModal";
import { useTranslation } from "react-i18next";
import Element from "../../core/helpers/Element";

const MenuStyled = styled(Menu)`
	.MuiPaper-elevation8 {
		box-shadow: rgba(50, 50, 93, 0) 0px 2px 5px -1px,
			rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
		margin-top: 0px;
		margin-left: -20px;  
	}
`
type TableFormProps = {
		collections?: any;
		pagination?: {
				total: number;
				current: number;
				pageSize: number;
		};
		page: number;
		setPage: (page: number) => void;
		columns: string[];
		tableName?: string;
		elementName: string;
		elementType?: string;
		elementOptions?: Array<any>;
		status?: boolean;
		isLoading?: boolean;
		deleteOption?: boolean;
		duplicateOption?: boolean;
		ecrfOption?: boolean;
}

const TableForm = ({
	 collections,
	 pagination,
	 tableName,
	 setPage,
	 status,
	 columns,
	 elementName,
	 elementType,
	 elementOptions,
	 isLoading,
	 deleteOption,
	 duplicateOption,
	 ecrfOption = false
}: TableFormProps) => {
		const dispatch = useAppDispatch();
		// const navigate = useNavigate();
		const { enqueueSnackbar } = useSnackbar();
		const userProfile = useAppSelector(selectUserProfile);
		const { t } = useTranslation();

		const { id, section } = useParams<{ id: string, section?: string }>();
		const [rows, setRows] = useState<any[]>([]);
		const [isDisabledButton, setIsDisabledButton] = useState(false);
		const [anchorEl, setAnchorEl] = useState(null);
		const [row, setRow] = useState<any>(null);
		const [openHistory, setOpenHistory] = useState<any>(false);

		const open = Boolean(anchorEl);
		const handleClick = (event: any, row: any) => {
				setAnchorEl(event.currentTarget)
				setRow(row)
		}

		const handleClose = () => {
				setAnchorEl(null)
				setRow(null)
		}

		useEffect(() => {
				const data: any[] = []
				// setRows([])

				if (collections?.rows?.length > 0) {
						collections?.rows.forEach((row: any) => {
								let collection: any = [];
								if (row?._embedded?.elements || row?.elements) {
										collection.push({
												...row,
												children: collections?.children,
												elements: row?._embedded?.elements || row?.elements || [],
												ecrf: collections?.ecrf || row?._embedded?.ecrf || row?.ecrf,
												elementId: row?._embedded?.element?.id || row?.element?.id || row?._embedded?.dataElement?.id,
												dataId: collections?.dataId,
										})

										delete collection['_embedded']
										delete collection?._embedded
								} else {
										collection = row
								}

								data.push(...collection)
						})

						setRows(data)
				}
		}, [collections])

		const openModalCollection = async (type: string) => {
				handleClose()

				// @todo nav current element in path url
				// navigate(ROUTES_ECRF.DETAILS_FULL_EXTENDED(id || '', section || '', element?.id))

				const ecrfId    = row?._embedded?.ecrf?.id    || row?.ecrf?.id || id
				// const elementId = row?._embedded?.element?.id || row?.element?.id || row?._embedded?.dataElement?.id || element?.id
				const elementId = row?._embedded?.element?.id || row?.element?.id || row?._embedded?.dataElement?.id
				const children  = row?._embedded?.children    || row?.children
				const elements  = row?._embedded?.elements    || row?.elements

				delete row['_embedded']
				await dispatch(
						fetchCollection({
								...row,
								children,
								collectionId: row?.id,
								elements,
								ecrfId,
								elementId,
								elementName,
								elementType: elementType,
								options: elementOptions || [],
								status,
								type
						})
				).unwrap()
		}

		/**
		 * Fetch history data of selected element
		 * @param id
		 */
		const handleHistory = async (id: string) => {
				setOpenHistory(row?.id)
		};

		const deleteCollection = async () => {
				handleClose()
				setIsDisabledButton(true)

				try {
						row?.id && await dispatch(deleteElementDataCollection(row.id))
						await dispatch(fetchCollection(null))

						enqueueSnackbar(t('record-deleted'), {variant: 'info'});
				} catch (error: any) {
						enqueueSnackbar(error?.detail || error?.message, {variant: 'warning'});
				}

				setIsDisabledButton(false)
		}

		const createColumns = () => {
				const data: any[] = []

				if (columns && rows && rows.length > 0) {
					rows?.[0].ecrf?.screeningNumber
						? data.push({
								title: t('screening-number'),
								render: (row: any) =>  (
										<Box>{row?.ecrf?.screeningNumber}</Box>
								),
								key: 'screeningNumber',
						})
						: data.push({
							title: t('randomization-number'),
							render: (row: any) =>  (
								<Box ml = "20px" > {row?.ecrf?.randomId || '--'}</Box>
							),
							key: 'randomId',
						})

					data.push(
								...columns.map((col: any) => {
										const findElement = (row: any) => {
                      return row?.elements?.find((data: any) => (data.elementName === col?.name))
										}

										return {
												title: <Box ml="20px">{col?.label}</Box>,
												render: (row: any) => (
														<Box>
																{Element.dataFormat(findElement(row))}
														</Box>
												),
												key: col?.id,
										}
								}),
						)

						{ecrfOption && !status && (
								data.push({
										title: <Box textAlign={'center'} ml="20px">Ecrf</Box>,
										render: (row: any) => (
												<Box textAlign={'center'}>
														<Box display="inline-flex">
																<Button
																		component={Link}
																		disabled={!row?.ecrf?.id || !row?.sectionId}
																		to={(ROUTES_ECRF.DETAILS_EXTENDED(row?.ecrf?.id || '', row?.sectionId || ''))}
																		onClick={() => {
																				if (row?.ecrf?.id && row?.sectionId) {
																						dispatch(getSectionId({section: row?.sectionId}))
																				}
																		}}
																		variant="outlined"
																		color="primary"
																		size="small"
																		endIcon={<CallMadeIcon/>}
																>
																		{t('go')}
																</Button>
														</Box>
												</Box>
										),
										key: 'ecrf',
								})
						)}

						data.push({
								title: <Box ml="20px"></Box>,
								render: (row: any) => (
										<Box ml="20px">
												<Button
														id="basic-button"
														aria-controls="basic-menu"
														aria-haspopup="true"
														aria-expanded={open ? 'true' : undefined}
														onClick={(event: any) => handleClick(event, row)}
														variant="contained"
														color="primary"
														size="small"
														disabled={isLoading || isDisabledButton}
												>
														{t('action')}
												</Button>
										</Box>
								),
								key: 'action',
						})
				}

				return data
		}

		return (
				<>
						<Table
							columns={createColumns()}
							pagination={pagination}
							data={rows || []}
							tableName={tableName}
							onChangePage={(page) => !isLoading && setPage(page)}
							isLoading={isLoading}
						/>

						<MenuStyled
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{'aria-labelledby': 'basic-button'}}
								style={{boxShadow: 'none'}}
						>
								<MenuItem
										onClick={() => openModalCollection('edit')}>
										<Typography variant="body2">{!status ? t('edit') : t('view')}</Typography>
								</MenuItem>
								{userProfile.roles.some(
										(role: string) => roles.admin.includes(role) || roles.monitor.includes(role)
								) && (
										<MenuItem onClick={() => handleHistory('history')}>
												<Typography variant="body2">{t('history')}</Typography>
										</MenuItem>
								)}
								{duplicateOption && !status && (
										<MenuItem onClick={() => openModalCollection('duplicate')}>
												<Typography variant="body2">{t('duplicate')}</Typography>
										</MenuItem>
								)}
								{deleteOption && !status && (
										<MenuItem divider>
												<ConfirmationAction
														confirmationTitle={t('are-you-sure-you-want-to-delete-this-record')}
														onClick={() => deleteCollection()}
														isProcessing={isLoading || isDisabledButton}
												>
														{({handleClick}) => <div onClick={handleClick}>
																<Typography variant="body2">{t('delete')}</Typography>
														</div>}
												</ConfirmationAction>
										</MenuItem>
								)}
						</MenuStyled>

						{/** Modal history */}
						<RowHistoryModal open={openHistory} onClose={() => setOpenHistory(false)}/>
				</>
		)
}

export default TableForm
