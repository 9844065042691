import {FormikValues, useFormikContext} from "formik";
import { useCallback, useEffect } from "react";
import usePrevious from "react-use/lib/usePrevious";
import { diff } from "deep-object-diff";
import { useAppSelector } from "commons/store/hooks";
import { selectElementDataCollection } from "features/ecrf/redux/ecrfSlice";
import { forEach, isEmpty } from "lodash";

type ModalAutoSaveParams = {
  delay?: number;
  isSaving?: boolean;
  setSaving: (isSaving: boolean) => void;
  onSave: (values: any, elements: any) => any;
};

const ModalAutoSave = ({
  delay = 500,
  isSaving,
  setSaving = () => {},
  onSave,
}: ModalAutoSaveParams) => {
  const { dirty, values } = useFormikContext<FormikValues>();
  const prevValues = usePrevious(values);
  const elementDataCollection = useAppSelector(selectElementDataCollection)

  // const isSameValues = async (v: FormikValues) => isEqual(v, prevValues);

  const onChange = useCallback(async () => {
    setSaving(true)
    // const v: FormikValues = omit(values, Object.keys(errors));
    // if (onSubmit && !(await isSameValueAsInitialValue(v))) onSubmit(v);

    let diffs = diff(prevValues as object, values as object);
    if (!isEmpty(diffs)) {
      forEach(diffs, (data, elementDataId) => {
        if (typeof data === 'object' && data !== null) {
          diffs = {
            ...diffs,
            [elementDataId]: (values as any)[elementDataId],
          };
        }
      });

      dirty && onSave(diffs, elementDataCollection?.elements);
    }

    setSaving(false)
  }, [values, prevValues, dirty]);

  useEffect(() => {
    if (prevValues) {
      const timer = setTimeout(() => onChange(), delay);
      return () => clearTimeout(timer);
    }
  }, [values]);

  return null;
};

export default ModalAutoSave;
